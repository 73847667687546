import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const ProductModule = namespace('product');

@Component({})
export default class ManageProductMixin extends Vue {
  @ProductModule.Action updateProduct!: any;

  async updateProductStore(id: Number, product: Object, certificate: Object) {
    await this.updateProduct({
      ...product,
      certificate_layout_id: id,
      certificate_layout: {
        ...certificate,
        id: id,
      },
    });
  }
}
