










































import { Component, Mixins, Prop } from 'vue-property-decorator';
import MButton from '@/shared/components/MButton.vue';
import Clipboard from '@/shared/mixins/ClipboardClass';
import ToastHelper from '@/shared/helpers/toast';
import liquidVariables, { TriggerType } from '@/data/liquid_variables_by_triggers';
import _ from 'lodash';

@Component({
  components: {
    MButton,
  },
})
export default class LiquidVariablesCopier extends Mixins(Clipboard) {
  @Prop({ required: false }) trigger: TriggerType;
  @Prop({ required: false }) customVariables: object;
  @Prop({ required: false }) url: string;

  readonly defaultVariables = {
    school_url: 'URL da plataforma',
    'school.name': 'Nome da plataforma',
  };

  selected_variable = '';

  get variables() {
    return this.customVariables;
  }

  copyVariable(variable) {
    this.selected_variable = variable;
    this.clipboardCopy('variable', `"{{ ${variable} }}"`);

    ToastHelper.successMessage('Variável copiada com sucesso');
  }

  redirectTo() {
    window.open(this.url, '_blank');
  }
}
