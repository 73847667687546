<template>
  <div class="d-flex flex-wrap">
    <label
      v-for="(item, index) in items"
      :key="index"
      class="p-3 mt-2 mr-2 mb-0 d-flex align-items-center justify-content-center text-center"
      :class="[customClasses, { checked: selected == item.value, hidden: item.hidden }]"
      :style="dynamicCustomStyle"
    >
      <img v-if="item.image" :src="item.image" :alt="item.value" class="mw-100" />
      <h6 v-else-if="item.label" class="item-label text-center font-weight-bold lh-150">{{ item.label }}</h6>
      <input
        class="d-none"
        type="radio"
        name="options"
        :value="item.value"
        v-model="selected"
        @change="selectedChanged"
      />
      <div>
        <hs-icon v-if="item.icon" :icon="item.icon" class="icon-text d-block text-primary mb-1" />
        <span>
          <strong>
            {{ item.text }}
          </strong>
        </span>
      </div>
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'hs-radio-btn-group',
  props: {
    value: {
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    customClasses: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default() {
        return {
          maxWidth: '150px',
        };
      },
    },
  },
  computed: {
    dynamicCustomStyle() {
      return {
        ...this.customStyle,
      };
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  methods: {
    selectedChanged() {
      this.$emit('change', this.selected);
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  cursor: pointer;
  background: $grey-10;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  line-height: 19px;
  color: $grey-25;
  letter-spacing: -0.233333px;
  transition: 0.5s all;
  color: $grey;
}

label:not(.checked) {
  border: 2px solid $grey-20;
  filter: grayscale(1);
  i {
    color: $grey !important;
  }
}

label.checked {
  border: 2px solid $cyan;
  background: $white;
}

.icon-text {
  font-size: 20px;
  color: $grey;
}

.item-label {
  color: $grey-30;
}

.hidden {
  display: none !important;
}
</style>
