










































































































import _ from 'lodash';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import PageLayout from '@/layout/PageLayout.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import hsRadioBtnGroup from '@/components/RadioBtnGroup.vue';
import PremiumBadge from '@/components/PremiumBadge/index.vue';
import Loading from '@/components/Loading.vue';
import CertificateViewer from '../../components/CertificateViewer.vue';
import ImageUploader from '@/myspark/views/StudentArea/Setup/components/ImageUploader.vue';
import MButton from '@/shared/components/MButton.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import Confirm from '@/shared/mixins/Confirm';
import ManageProductMixin from '@/sparkmembers/mixins/ManageProductMixin';
import ToastHelper from '@/shared/helpers/toast';

import { certificateService } from '@/services';

import CertificateTemplates from '@/libs/certificates/index';
import type { Certificate } from '@/types/certificate';

const SchoolModule = namespace('school');
const ProductModule = namespace('product');

@Component({
  components: {
    PageLayout,
    PageHeader,
    hsRadioBtnGroup,
    Loading,
    CertificateViewer,
    MButton,
    ImageUploader,
    MSwitch,
    PremiumBadge,
    HsPremiumModal: () => import('@/components/PremiumModal.vue'),
  },
})
export default class BasicCertificateEditor extends Mixins(Confirm, ManageProductMixin) {
  @SchoolModule.State('selectedSchool') school!: any;
  @ProductModule.State('selectedProduct') product!: any;

  certificates: Certificate[] = [];
  selectedCertificate: Certificate = {} as Certificate;

  form = {
    logo_url: '',
    signature_url: '',
    signature_text: '',
    remove_logo_hero: false,
  };

  isSaving = false;
  isLoading = false;

  @Watch('form', { deep: true })
  onChangeValue(value) {
    this.certificates = this.certificates.map(certificate => ({
      ...certificate,
      ...value,
    }));

    this.selectedCertificate = {
      ...this.selectedCertificate,
      ...value,
    };
  }

  mounted() {
    this.form = {
      logo_url: this.product?.certificate_layout?.logo_url || '',
      signature_url: this.product?.certificate_layout?.signature_url || '',
      signature_text: this.product?.certificate_layout?.signature_text || '',
      remove_logo_hero: this.product?.certificate_layout?.remove_logo_hero || false,
    };

    this.mountCertificatesList();
    this.initSelectedCertificate();
  }

  mountCertificatesList() {
    this.certificates = CertificateTemplates.map(
      (certificate, index) =>
        ({
          id: index + 1,
          advanced: false,
          bg_url: `https://s3.amazonaws.com/core_prod/certificates/v3/certificate_bg_${index + 1}.png`,
          logo_url: '',
          remove_logo_hero: false,
          signature_url: '',
          signature_text: this.$t('sparkmembers.certificates.views.editor.index.default-values.signature-text'),
          template: certificate,
          title: `Certificado ${index + 1}`,
        } as Certificate)
    );
  }

  initSelectedCertificate() {
    if (_.isNull(this.product.certificate_layout)) {
      this.selectedCertificate = this.certificates[0];
      return;
    }

    const { bg_url } = this.product.certificate_layout;
    const image_from_product = bg_url.split('/').pop();

    this.selectedCertificate =
      this.certificates.find(certificate => _.isEqual(certificate.bg_url.split('/').pop(), image_from_product)) ||
      this.certificates[0];
  }

  toggleLogoHeroOnTemplate(template: string) {
    return this.form.remove_logo_hero
      ? template.replace('<img class="logo-hero"', '<img class="logo-hero remove-logo"')
      : template.replace('<img class="logo-hero remove-logo"', '<img class="logo-hero"');
  }

  changeSelectedCertificate(certificate: Certificate) {
    const template = this.toggleLogoHeroOnTemplate(certificate.template);
    this.selectedCertificate = {
      ...certificate,
      template: template,
    };
  }

  async onSaveCertificate() {
    try {
      this.isSaving = true;

      const confirmed = await this.showConfirmBox({
        okTitle: this.$t('certificate.confirm-modal-save.btn-save'),
        cancelTitle: this.$t('general.cancel'),
        contentTitle: this.$t('certificate.confirm-modal-save.title'),
        contentDescription: this.$t('certificate.confirm-modal-save.subtitle'),
        variant: 'purple',
        icon: 'info-circle',
        modalClass: '',
        image: '',
      });

      if (!confirmed) return;

      if (!this.product.certificate_layout?.id) {
        const res = await certificateService.create({
          school_id: this.school.id,
          ...this.selectedCertificate,
        });

        await this.updateProductStore(res.id, this.product, this.selectedCertificate);
      } else {
        await certificateService.update({
          ...this.selectedCertificate,
          id: this.product.certificate_layout?.id,
        });

        await this.updateProductStore(this.product.certificate_layout.id, this.product, this.selectedCertificate);
      }

      ToastHelper.successMessage(this.$t('sparkmembers.certificates.views.editor.index.toast.success.edit'));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.certificates.views.editor.index.toast.error.edit'));
    } finally {
      this.isSaving = false;
    }
  }

}
