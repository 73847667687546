import { render, staticRenderFns } from "./CertificateViewer.vue?vue&type=template&id=6d66d5d7&scoped=true&"
import script from "./CertificateViewer.vue?vue&type=script&lang=ts&"
export * from "./CertificateViewer.vue?vue&type=script&lang=ts&"
import style0 from "./CertificateViewer.vue?vue&type=style&index=0&id=6d66d5d7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d66d5d7",
  null
  
)

export default component.exports