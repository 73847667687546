import { certificate1 } from './certificate_1';
import { certificate2 } from './certificate_2';
import { certificate3 } from './certificate_3';
import { certificate4 } from './certificate_4';
import { certificate5 } from './certificate_5';
import { certificate6 } from './certificate_6';

const CertificateTemplates = [certificate1, certificate2, certificate3, certificate4, certificate5, certificate6];

export default CertificateTemplates;
