export const certificate1 = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <title>
        Certificado do curso {{course.title}}
    </title>
    <link href="https://fonts.googleapis.com/css2?family=Muli&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Parisienne&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap" rel="stylesheet" />
</head>

<body>
    <meta charset="UTF-8" />
    <div class="certificate">

        <div class="certificate-title">
            <h1>CERTIFICADO</h1>
            <h3>DE CONCLUSÃO</h3>
        </div>

        <div class="certificate-logo"></div>

        <h2 class="student-name">{{ student.name }}</h2>

        <div class="certificate-text">
            <p>Concluiu com total aproveitamento o curso</p>
            <strong>{{ course.title }}</strong>
            <p class="course-duration">Carga horária: {{ course.duration }} horas</p>
        </div>

        <div class="certificate-signature">
            <div class="signature-img"></div>
            <p>{{ school.owner }}</p>
        </div>

        <div class="certificate-date">{{ today }}</div>

        <img class="logo-hero" src="https://s3.amazonaws.com/core_prod/certificates/logo-hero-white.png" />
    </div>

    <style type="text/css">
        .certificate {
            position: relative;
            width: 960px;
            height: 680px;
            font-family: 'Muli', sans-serif;
            color: white;
            margin: 0 auto;
            overflow: hidden;

            background-image: url({{ bg_url }});
            background-position: center;
            background-size: cover;

        }

        .certificate-title {
            position: absolute;
            top: 212px;
            left: 72px;
            font-size: 60px;
            font-weight: bold;
        }

        .certificate-title h1 {
            font-size: 48px;
            font-weight: bold;
            margin: 0;
        }

        .certificate-title h3 {
            font-size: 24px;
            font-weight: 300;
            margin: 0;
        }

        .certificate-logo {
            position: absolute;
            top: 48px;
            right: 216px;

            width: 150px;
            height: 130px;

            background-image: url({{ logo_url }});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .student-name {
            position: absolute;
            width: 650px;
            top: 368px;
            left: 72px;
            margin: 0;
            font-family: 'Parisienne', cursive;
            font-size: 45px;
            font-weight: 400;
            margin-bottom: 30px;
        }

        .certificate-text {
            position: absolute;
            width: 400px;
            bottom: 72px;
            left: 48px;
            font-size: 16px;
            line-height: 1.5;
        }

        .certificate-text p {
            margin-bottom: 0;
            font-weight: 300;
            margin-top: 0;
        }

        .certificate-text strong {
            font-weight: 900;
        }

        .certificate-text .course-duration {
            margin-top: 10px;
        }

        .certificate-signature {
            position: absolute;
            bottom: 72px;
            right: 260px;
            width: 200px;
            text-align: center;
        }

        .certificate-signature p {
            margin-top: 10px;
        }

        .certificate-signature .signature-img {
            width: 200px;
            height: 70px;
            background-image: url({{ signature_url }});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;

            border-bottom: 1px solid white;
        }

        .certificate-date {
            position: absolute;
            bottom: 113px;
            right: 48px;
            font-size: 14px;
            font-weight: 300;
        }

        .logo-hero {
            position: absolute;
            width: 114px;
            bottom: 75px;
            right: 48px;
        }

        .remove-logo {
            display: none;
            visibility: hidden;
            width: 0;
        }
    </style>
</body>
</html>`;
