import copy from 'copy-to-clipboard';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Clipboard extends Vue {
  clipboardCopy(_ref, value = '') {
    try {
      return copy(value);
    } catch (e) {
      return false;
    }
  }
}
