<template>
  <div class="tlw-w-full">
    <div class="tlw-flex position-relative image-uploader tlw-w-full image-uploader--complete" v-if="isLoading">
      <div
        class="
          tlw-relative tlw-flex tlw-flex-col tlw-items-center tlw-justify-center
          overflow-hidden
          image-uploader__container
          tlw-w-full
        "
        :style="{ width: `${width}px`, height: `${height}px` }"
      >
        <Loading />
      </div>
    </div>
    <div v-else>
      <div :class="`tlw-flex tlw-relative image-uploader tlw-w-full image-uploader--${variant}`" v-if="image === ''">
        <label
          class="
            tlw-relative tlw-flex tlw-flex-col tlw-items-center tlw-justify-center
            overflow-hidden
            image-uploader__container
            tlw-w-full tlw-mb-0
          "
          :style="{ width: `${width}px`, height: `${height}px` }"
        >
          <hs-icon icon="image" />
          <p class="mb-0">
            {{ $t('myspark.student-area.arraste') }}<br />
            ou
            <span class="grape-text">{{ $t('myspark.student-area.click') }}</span>
          </p>
          <input class="tlw-hidden" type="file" @input="onFile" :disabled="disabled" />
        </label>
      </div>
      <div class="img tlw-w-full" :style="{ width: `${width}px`, height: `${height}px` }" v-else>
        <img :src="image" class="thumbnail" :style="{ width: `${width}px`, height: `${height}px` }" />
        <button
          @click="removeImage"
          class="tlw-p-0 rounded-circle tlw-items-center"
          v-b-tooltip.hover.right="$t('myspark.student-area.tooltip-delete')"
        >
          <hs-icon icon="trash-alt" :size="13" />
        </button>
        <input type="hidden" :value="image" />
      </div>
    </div>
  </div>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import ImageUploaderService from '@/services/imageUploadService';
import Loading from '@/components/Loading.vue';

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      acceptedFormats: ['image/png', 'image/jpeg'],
      imgData: this.image,
    };
  },
  props: {
    image: {
      default: '',
      type: String,
    },
    schoolId: Number,
    height: String,
    width: String,
    disabled: Boolean,
    variant: {
      default: 'complete',
      type: String,
    },
  },
  methods: {
    async onFile(event) {
      this.isLoading = true;

      try {
        const files = [...event.srcElement.files];
        if (files.length) {
          if (!files.every(file => this.acceptedFormats.some(format => file.type.match(format)))) {
            ToastHelper.dangerMessage($t('components.image-uploader.upload-error-formats'));
            return;
          }
        }
        const file = files[0];

        await ImageUploaderService.sendFileToAWS(file, this.schoolId).then(response => {
          this.imgData = response;
        });

        this.$emit('input', this.imgData);
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('Não foi possivel adicionar a imagem.'));
      } finally {
        this.isLoading = false;
      }
    },
    removeImage() {
      this.imgData = '';
      this.$emit('input', this.imgData);
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  border: 1px dashed $purple-dark;
  box-sizing: border-box;
  border-radius: 4px;
  object-fit: cover;
  width: 100%;
}

div.img {
  position: relative;
}
div.img > button {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-content: center;
  i {
    color: #db3939;
    font-weight: 600;
  }
  &:hover {
    background-color: #ddd;
  }
}

.image-uploader {
  &__container {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
    p {
      font-family: 'Mulish';
      font-style: normal;
      font-size: 14px;
      line-height: 150%;
      color: #6f6f6f;
      font-weight: 400;
      text-align: center;
    }
    .grape-text {
      color: $purple-dark;
      text-decoration: underline;
    }
  }
  &--complete {
    .image-uploader__container {
      border: 1px dashed $purple-dark;
    }
    .image-uploader__close {
      outline: none;
      right: -10px;
      top: -10px;
    }
  }
  &--disabled {
    .image-uploader__container {
      border: 2px dashed #5e5d5d;
      background: #cfcfcf;
      opacity: 0.4;
      cursor: not-allowed;
      img {
        opacity: 0.4;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .image-uploader__container {
    width: 100% !important;
    padding: 8px;
  }
  div.img,
  .thumbnail {
    width: 100% !important;
  }
  .image-uploader__container p {
    font-size: 12px;
  }
}
</style>
