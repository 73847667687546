export const certificate2 = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <title>
        Certificado do curso {{ course.title }}
    </title>
    <link href="https://fonts.googleapis.com/css2?family=Muli&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Parisienne&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap" rel="stylesheet" />
</head>

<body>
    <meta charset="UTF-8" />
    <div class="certificate">

        <div class="certificate-title">
            <h1>CERTIFICADO</h1>
            <h3>DE CONCLUSÃO</h3>
        </div>

        <div class="certificate-logo"></div>
        
        <div class="certificate-text">
            <h2 class="student-name">{{ student.name }}</h2>
            <p>Concluiu com total aproveitamento o curso</p>
            <strong>{{ course.title }}</strong>
            <p class="course-duration">Carga horária: {{ course.duration }} horas</p>
        </div>

        <img class="logo-hero" src="https://s3.amazonaws.com/core_prod/certificates/logo-hero-black.png" />

        <div class="certificate-date">{{ today }}</div>

        <div class="certificate-signature">
            <div class="signature-img"></div>
            <p>{{ school.owner }}</p>
        </div>        
    </div>

    <style type="text/css">
        .certificate {
            position: relative;
            width: 960px;
            height: 680px;
            font-family: 'Muli', sans-serif;
            margin: 0 auto;
            overflow: hidden;

            background-image: url({{ bg_url }});
            background-position: center;
            background-size: cover;

        }

        .certificate-title {
            position: absolute;
            top: 124px;
            left: 192px;
            font-size: 60px;
            font-weight: bold;
            color: #4D1AA1;
        }
        
        .certificate-title h1 {
            font-size: 48px;
            font-weight: bold;
            margin: 0;
        }

        .certificate-title h3 {
            font-size: 24px;
            font-weight: 400;
            margin: 0;
        }
            
        .certificate-logo {
            position: absolute;
            text-align: center;
            top: 124px;
            right: 64px;
            width: 150px;
            height: 130px;

            background-image: url({{ logo_url }});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .student-name {
            width: 650px;
            font-family: 'Parisienne', cursive;
            font-weight: normal;
            color: #262626;
            font-size: 45px;
            line-height: 40px;
            margin-bottom: 25px;
        }

        .certificate-text {
            position: absolute;
            width: 560px;
            top: 330px;
            left: 64px;
            font-size: 16px;
            line-height: 1.5;
        }

        .certificate-text p {
            margin-bottom: 0;   
            font-weight: 400;
        }

        .certificate-text strong {
            font-weight: 900;
        }

        .certificate-text .course-duration {
            margin-top: 10px;   
        }

        .certificate-date {
            color: #262626;
            font-size: 14px;
            position: absolute;
            bottom: 102px;
            right: 315px;
            margin: 0;
        }

        .logo-hero {
            position: absolute;
            width: 114px;
            bottom: 64px;
            right: 316px;
        }

        .remove-logo {
            display: none;
            visibility: hidden;
            width: 0;
        }

        .certificate-signature {
            position: absolute;
            text-align: center;
            color: #262626;
            bottom: 64px;
            right: 64px;
            width: 200px;
            margin: 0;
        }

        .certificate-signature p {
            margin-top: 10px;
            margin-bottom: 0px
        }

        .certificate-signature .signature-img {
            width: 200px;
            height: 70px;
            background-image: url({{ signature_url }});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;

            border-bottom: 1px solid #262626;
        }        
    </style>
</body>
</html>`;
