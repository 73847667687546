























































































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { between } from 'vuelidate/lib/validators';

import PageLayout from '@/layout/PageLayout.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import Loading from '@/components/Loading.vue';
import MButton from '@/shared/components/MButton.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import PremiumBadge from '@/components/PremiumBadge/index.vue';
import ToastHelper from '@/shared/helpers/toast';
import isNull from 'lodash/isNull';
import BrowserHelper from '@/shared/helpers/browser';

const ProductModule = namespace('product');

@Component({
  components: {
    PageLayout,
    PageHeader,
    Loading,
    MButton,
    MSwitch,
    PremiumBadge,
    HsPremiumModal: () => import('@/components/PremiumModal.vue'),
    AutoPilotBanner: () => import('@/components/AutoPilotBanner.vue'),
  },
  validations: {
    form: {
      certification_min_progress: {
        between: between(1, 100),
      },
    },
  },
})
export default class CertificateConfiguration extends Vue {
  @ProductModule.State('selectedProduct') product!: any;
  @ProductModule.Action updateProduct!: any;

  form = {
    certification: false,
    certification_min_progress: null,
    duration: 0,
  };

  isSavingCertificate = false;

  mounted() {
    this.form = {
      certification: this.product.certification || false,
      certification_min_progress: this.product.certification_min_progress || null,
      duration: this.product.duration || 0,
    };
  }

  get canActiveCertificateView() {
    return this.$FCL.canActiveCertificateView();
  }

  get canAutomaticPilot() {
    return this.$ACL.canAutomaticPilotPage();
  }

  get certificate_type() {
    if (isNull(this.product.certificate_layout)) {
      return false;
    }

    if (this.product.certificate_layout.advanced) {
      return 'advanced';
    }

    return 'basic';
  }

  get isMobile() {
    return BrowserHelper.isMobile();
  }

  requestPremiumFeature() {
    this.$router.push({ name: 'MyAccountPlans' });
    this.$bvModal.hide('premium-modal');
  }

  async saveCertificateConfiguration() {
    try {
      this.isSavingCertificate = true;

      await this.updateProduct({
        ...this.product,
        ...this.form,
      });

      ToastHelper.successMessage(this.$t('sparkmembers.certificates.views.editor.index.toast.success.edit'));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.certificates.views.editor.index.toast.error.edit'));
    } finally {
      this.isSavingCertificate = false;
    }
  }

  goToCertificateBasicEditor() {
    this.$router.push({ name: 'CertificatesBasicEditor' });
  }

  goToCertificateAdvancedEditor() {
    if (this.canActiveCertificateView) {
      this.$router.push({ name: 'CertificatesAdvancedEditor' });
    } else {
      this.$bvModal.show('premium-modal');
    }
  }
}
