





























































































































import _ from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import PageHeader from '@/components/_structures/PageHeader.vue';
import IframeEdools from '@/components/IframeEdools/index.vue';
import LiquidVariablesCopier from '@/components/LiquidVariablesCopier.vue';
import ImageUploader from '@/myspark/views/StudentArea/Setup/components/ImageUploader.vue';
import CertificateViewer from '../../components/CertificateViewer.vue';
import MButton from '@/shared/components/MButton.vue';
import ToastHelper from '@/shared/helpers/toast';
import certificateService from '@/sparkmembers/services/certificate';

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.min.css';

import { certificate_advanced } from '@/libs/certificates/certificate_advanced';
import Confirm from '@/shared/mixins/Confirm';
import ManageProductMixin from '@/sparkmembers/mixins/ManageProductMixin';
import { Certificate } from '@/types/certificate';

const SchoolModule = namespace('school');
const ProductModule = namespace('product');

@Component({
  components: {
    PageHeader,
    LiquidVariablesCopier,
    IframeEdools,
    PrismEditor,
    CertificateViewer,
    ImageUploader,
    MButton,
  },
})
export default class AdvancedEditor extends Mixins(Confirm, ManageProductMixin) {
  @SchoolModule.State('selectedSchool') school!: any;
  @ProductModule.State('selectedProduct') product!: any;

  readonly variables = {
    logo_url: 'URL para o logo da escola',
    bg_url_advanced: 'URL para o plano de fundo',
    signature_url: 'URL para a assinatura',
    today: 'Data de hoje',
    'course.duration': 'Carga horária do curso',
    'course.teacher': 'Instrutor do curso',
    'student.cpf': 'CPF do aluno',
    'student.name': 'Nome do aluno',
    'school.name': 'Nome da escola',
    'school.owner': 'Nome do dono da escola',
  }

  expanded_editor = false;
  isSaving = false;

  bg_url_default = 'https://s3.amazonaws.com/core_prod/certificates/v3/certificate_bg_4.png';

  certificate = {} as Certificate;

  mounted() {
    this.getCertificate();
  }

  getCertificate() {
    const { id, bg_url_advanced, logo_url, signature_url, signature_text, template_advanced, title } = this.product
      .certificate_layout
      ? this.product.certificate_layout
      : ({} as Certificate);

    this.certificate = {
      id: id || 0,
      advanced: true,
      bg_url_advanced: bg_url_advanced || this.bg_url_default,
      logo_url: logo_url || '',
      signature_url: signature_url || '',
      signature_text: signature_text || 'Nome da assinatura',
      template_advanced: template_advanced || certificate_advanced,
      title: title || 'Certificado Avançado',
    } as Certificate;
  }

  highlighter(code) {
    return highlight(code, languages.js, 'javascript');
  }

  expandEditor() {
    this.expanded_editor = !this.expanded_editor;
  }

  async resetTemplate() {
    try {
      const confirmed = await this.showConfirmBox({
        okTitle: this.$t('certificate.confirm-modal-save.btn-save'),
        cancelTitle: this.$t('general.cancel'),
        contentTitle: this.$t('certificate.confirm-modal-save.title'),
        contentDescription: this.$t('certificate.confirm-modal-save.subtitle'),
        variant: 'purple',
        icon: 'info-circle',
        modalClass: '',
        image: '',
      });

      if (!confirmed) return;

      this.certificate = {
        ...this.certificate,
        template_advanced: certificate_advanced,
        bg_url_advanced: this.bg_url_default,
      };

      ToastHelper.successMessage('Template resetado com sucesso');
    } catch (error) {
      ToastHelper.dangerMessage('Erro ao resetar template. Tente novamente!');
    }
  }

  async saveCertificate() {
    try {
      this.isSaving = true;

      const confirmed = await this.showConfirmBox({
        okTitle: this.$t('certificate.confirm-modal-save.btn-save'),
        cancelTitle: this.$t('general.cancel'),
        contentTitle: this.$t('certificate.confirm-modal-save.title'),
        contentDescription: this.$t('certificate.confirm-modal-save.subtitle'),
        variant: 'purple',
        icon: 'info-circle',
        modalClass: '',
        image: '',
      });

      if (!confirmed) return;

      if (!this.product.certificate_layout?.id) {
        const res = await certificateService.create({
          school_id: this.school.id,
          ...this.certificate,
        });

        await this.updateProductStore(res.id, this.product, this.certificate);
      } else {
        await certificateService.update({
          ...this.certificate,
          id: this.product.certificate_layout?.id,
        });
      }

      await this.updateProductStore(this.product.certificate_layout.id, this.product, this.certificate);

      ToastHelper.successMessage(this.$t('sparkmembers.certificates.views.editor.index.toast.success.edit'));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.certificates.views.editor.index.toast.error.edit'));
    } finally {
      this.isSaving = false;
    }
  }

}
