export const certificate5 = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <title>
        Certificado do curso {{course.title}}
    </title>
    <link href="https://fonts.googleapis.com/css2?family=Muli&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Parisienne&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap" rel="stylesheet" />
</head>

<body>
    <meta charset="UTF-8" />
    <div class="certificate">
        <div class="certificate-logo"></div>

        <h1 class="student-name">{{ student.name }}</h1>

        <div class="certificate-text">
            <p>Concluiu com total aproveitamento o curso</p>
            <strong>{{ course.title }}</strong>
            <p class="course-duration">Carga horária: {{ course.duration }} horas</p>
        </div>

        <div class="certificate-date">{{ today }}</div>

        <img class="logo-hero" src="https://s3.amazonaws.com/core_prod/certificates/logo-hero-black.png" />

        <div class="certificate-signature">
            <div class="signature-img"></div>
            <hr />
            <p>{{ school.owner }}</p>
        </div>
    </div>

    <style type="text/css">
        .certificate {
            position: relative;
            width: 960px;
            height: 680px;
            font-family: 'Muli', sans-serif;
            color: #333;
            margin: 0 auto;
            overflow: hidden;

            background-image: url({{bg_url}});
            background-position: center;
            background-size: cover;

        }

        .certificate-logo {
            position: absolute;
            top: 88px;
            left: 405px;

            width: 150px;
            height: 130px;

            background-image: url({{ logo_url }});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .student-name {
            position: absolute;
            width: 800px;
            top: 263px;
            left: 80px;
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 12px;
            text-align: center;
            margin: 0;
        }

        .certificate-text {
            position: absolute;
            width: 560px;
            top: 351px;
            left: 200px;
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
        }

        .certificate-text p {
            margin-bottom: 0;
            font-weight: 400;
            margin-top: 0;
        }

        .certificate-text strong {
            display: block;
            font-weight: 900;
            margin-top: 5px;
        }

        .certificate-text .course-duration {
            margin-top: 5px;
        }

        .certificate-date {
            position: absolute;
            bottom: 88px;
            left: 88px;
            font-size: 14px;
        }

        .logo-hero {
            position: absolute;
            bottom: 88px;
            left: 423px;
            width: 114px;
        }

        .remove-logo{
            display: none;
            visibility: hidden;
            width: 0;
        }

        .certificate-signature {
            position: absolute;
            bottom: 88px;
            right: 88px;
            width: 200px;
            text-align: center;
        }
        .certificate-signature p {
            margin-bottom: 0px;
        }

        .certificate-signature hr {
            border-color: black;
            margin-top: 0;
            margin-bottom: 8px;
        }

        .certificate-signature .signature-img {
            width: 200px;
            height: 70px;
            background-image: url({{ signature_url }});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
        }

    </style>
</body>
</html>`;
