import ToastHelper from '@/shared/helpers/toast';
import { uploadService } from '@/services';
export default {
  async sendFileToAWS(file, schoolId) {
    try {
      const { data } = await uploadService.getAwsKeys(file.type, schoolId);
      const imgURL = await uploadService.upload(data.url, data.fields, file);
      const newImage = { selected: false, url: imgURL, uploaded: true };
      return newImage.url;
    } catch (error) {
      return error;
    }
  },
};
