import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Confirm extends Vue {
  wrapper(contentArray) {
    return this.$createElement(
      'div',
      {
        class: ['d-flex', 'flex-column', 'justify-content-center', 'align-items-center'],
        style: {
          maxWidth: '400px',
          margin: '0 auto',
        },
      },
      contentArray
    );
  }

  image(image) {
    return this.$createElement('img', {
      class: ['mb-4'],
      attrs: {
        src: image,
      },
    });
  }

  icon(variant = 'primary', icon = 'bell') {
    return this.$createElement('hs-icon', {
      class: ['mb-4', `text-${variant}`],
      style: {
        fontSize: '47px',
        marginTop: '15px',
      },
      props: {
        icon,
      },
    });
  }

  title(contentTitle) {
    return this.$createElement('h4', {
      class: ['mb-3', 'font-weight-bold', 'text-center'],
      domProps: {
        innerHTML: contentTitle,
      },
    });
  }

  description(contentDescription) {
    return this.$createElement('p', {
      class: ['description', 'mx-4', 'my-0', 'text-center'],
      domProps: {
        innerHTML: contentDescription,
      },
    });
  }

  /**
   * @summary used for sync confirmations that does not require user input.
   * @returns resolves a promise with true (confirmed), false (canceled) or null (user clicked on x)
   */
  showConfirmBox({ okTitle, cancelTitle, contentTitle, contentDescription, variant, icon, modalClass, image }) {
    const body = this.wrapper([
      image ? this.image(image) : null,
      icon ? this.icon(variant, icon) : null,
      this.title(contentTitle),
      this.description(contentDescription),
    ]);

    const content = this.$createElement('div', {}, [body]);

    return this.$bvModal.msgBoxConfirm([content], {
      hideHeader: false,
      footerClass: 'confirm-footer p-2 my-4 border-top-0 d-flex justify-content-center align-items-center',
      headerClass: 'px-3 py-1 border-bottom-0',
      okVariant: variant,
      cancelVariant: 'outline-secondary',
      bodyClass: 'py-0',
      modalClass,
      okTitle,
      cancelTitle,
      hideHeaderClose: false,
      centered: true,
      size: 'smd',
      image,
    });
  }
}
