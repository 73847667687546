export const certificate_advanced = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <title data-testid="certificate-title">
        Certificado do curso {{course.title}}
    </title>
    <link href="https://fonts.googleapis.com/css2?family=Muli&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Parisienne&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap" rel="stylesheet" />
</head>

<body>
    <meta charset="UTF-8" />
    <div class="certificate">
        <div class="certificate-title">
            <h1>CERTIFICADO</h1>
            <h3>DE CONCLUSÃO</h3>
        </div>

        <div class="certificate-logo"></div>

        <h1 class="student-name">{{ student.name }}</h1>

        <div class="certificate-text">
            <p>Concluiu com total aproveitamento o curso</p>
            <strong>{{ course.title }}</strong>
            <p class="course-duration">Carga horária: {{ course.duration }} horas</p>
        </div>

        <div class="certificate-date">{{ today }}</div>

        <div class="certificate-signature">
            <div class="signature-img"></div>
            <p class="signature-text">{{ school.owner }}</p>
        </div>

        <img class="logo-hero" src="https://s3.amazonaws.com/core_prod/certificates/logo-hero-black.png" />
    </div>

    <style type="text/css">
        .certificate {
            position: relative;
            width: 960px;
            height: 680px;
            font-family: 'Muli', sans-serif;
            color: #333;
            margin: 0 auto;
            overflow: hidden;

            background-image: url({{ bg_url_advanced }});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .certificate-title {
            position: absolute;
            top: 107px;
            left: 200px;
            font-size: 60px;
            font-weight: bold;
        }

        .certificate-title h1 {
            font-size: 48px;
            font-weight: bold;
            margin: 0;
        }

        .certificate-title h3 {
            font-size: 24px;
            font-weight: 700;
            margin: 0;
        }

        .certificate-logo {
            position: absolute;
            top: 88px;
            right: 88px;

            width: 150px;
            height: 130px;

            background-image: url({{ logo_url }});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .student-name {
            position: absolute;
            top: 260px;
            left: 88px;
            font-size: 40px;
            font-weight: 700;
            margin: 0;
        }

        .certificate-text {
            position: absolute;
            width: 560px;
            top: 348px;
            left: 88px;
            font-size: 16px;
            line-height: 1.5;
        }

        .certificate-text p {
            margin-bottom: 0;
            font-weight: 400;
            margin-top: 0;
        }

        .certificate-text strong {
            display: block;
            font-weight: 900;
            margin-top: 5px;
        }

        .certificate-text .course-duration {
            margin-top: 5px;
        }

        .certificate-date {
            position: absolute;
            bottom: 88px;
            left: 88px;
            font-size: 16px;
        }

        .certificate-signature {
            position: absolute;
            bottom: 88px;
            right: 88px;
            width: 200px;
            text-align: center;
        }

        .certificate-signature p {
            font-size: 16px;
            margin-top: 5px;
            margin-bottom: 0px;
        }

        .certificate-signature .signature-img {
            width: 200px;
            height: 70px;
            background-image: url({{ signature_url }});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;

            border-bottom: 1px solid #262626;
        }

        .logo-hero {
            position: absolute;
            bottom: 88px;
            right: 409px;
            width: 114px;
        }

        .remove-logo {
            display: none;
            visibility: hidden;
            width: 0;
        }
    </style>
</body>
</html>`;
