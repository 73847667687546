






import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Certificate } from '@/types/certificate';

@Component
export default class CertificateViewer extends Vue {
  @Prop({ required: true }) selectedCertificate!: Certificate;
  @Prop({ required: true }) template!: string;
  @Prop({ required: true }) product!: any;

  isAdvanced = false;

  get templateCompiled() {
    if (_.isEmpty(this.selectedCertificate)) return {};

    const { signature_text } = this.selectedCertificate;

    this.isAdvanced = this.selectedCertificate.advanced;

    const certificateParams = {
      ...this.selectedCertificate,
      school: {
        name: this.$t('sparkmembers.certificates.views.editor.index.default-values.school-name'),
        owner:
          signature_text || this.$t('sparkmembers.certificates.views.editor.index.default-values.signature-text'),
      },
      course: {
        title: this.product.title,
        duration: this.product?.duration || 0,
      },
      student: {
        name: this.$t('sparkmembers.certificates.views.editor.index.default-values.student-name'),
      },
      today: dayjs().format('DD/MM/YYYY, HH:mm'),
    };

    const compiled = _.template(this.template, {
      interpolate: /{{([\s\S]+?)}}/g,
    });

    return compiled(certificateParams);
  }
}
