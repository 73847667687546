export const certificate3 = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <title>
        Certificado do curso {{course.title}}
    </title>
    <link href="https://fonts.googleapis.com/css2?family=Muli&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Parisienne&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap" rel="stylesheet" />
</head>

<body>
    <meta charset="UTF-8" />
    <div class="certificate">

        <div class="certificate-logo"></div>

        <h2 class="student-name">{{ student.name }}</h2>

        <div class="certificate-text">
            <p>Concluiu com total aproveitamento o curso</p>
            <strong>{{ course.title }}</strong>
            <p class="course-duration">Carga horária: {{ course.duration }} horas</p>
        </div>

        <div class="certificate-date">{{ today }}</div>

        <img class="logo-hero" src="https://s3.amazonaws.com/core_prod/certificates/logo-hero-red.png" />

        <div class="certificate-signature">
            <div class="signature-img"></div>
            <p>{{ school.owner }}</p>
        </div>
    </div>

    <style type="text/css">
        .certificate {
            position: relative;
            width: 960px;
            height: 680px;
            font-family: 'Muli', sans-serif;
            margin: 0 auto;
            overflow: hidden;

            background-image: url({{ bg_url }});
            background-position: center;
            background-size: cover;

        }

        .certificate-logo {
            position: absolute;
            text-align: center;
            bottom: 80px;
            left: 405px;
            width: 150px;
            height: 130px;

            background-image: url({{ logo_url }});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .student-name {
            position: absolute;
            top: 226px;
            left: 130px;
            width: 700px;
            text-align: center;
            color: #262626;
            margin: 0;
            font-size: 35px;
            font-weight: bold;
            line-height: 35px;
        }

        .certificate-text {
            position: absolute;
            width: 500px;
            top: 318px;
            left: 230px;
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
        }

        .certificate-text p {
            margin-bottom: 0;
            font-weight: 400;
            margin: 0;
        }

        .certificate-text strong {
            font-weight: 900;
        }

        .certificate-text .course-duration {
            margin-top: 10px;
        }

        .certificate-date {
            position: absolute;
            bottom: 110px;
            left: 128px;
            font-size: 14px;
            margin: 0;
        }

        .logo-hero {
            position: absolute;
            width: 114px;
            bottom: 80px;
            left: 132px;
        }

        .remove-logo {
            display: none;
            visibility: hidden;
            width: 0;
        }

        .certificate-signature {
            position: absolute;
            text-align: center;
            color: #262626;
            bottom: 80px;
            right: 113px;
            width: 200px;
            margin: 0;
        }

        .certificate-signature p {
            font-size: 16px;
            margin-top: 5px;
        }

        .certificate-signature .signature-img {
            width: 200px;
            height: 70px;
            background-image: url({{ signature_url }});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;

            border-bottom: 1px solid #262626;
        }
    </style>
</body>
</html>`;
