







import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Loading from '@/components/Loading.vue';

const SchoolModule = namespace('school');
const AuthModule = namespace('auth');

@Component({
  components: {
    Loading,
  },
})
export default class Forum extends Vue {
  @Prop({ required: true }) page: string;
  @Prop({}) params: { [key: string]: string } = {};
  @Ref() edoolsIframe!: any;

  @SchoolModule.State selectedSchool!: any;
  @AuthModule.State loggedUser!: any;

  src: string = '';
  isLoading: boolean = true;

  mounted() {
    this.mountURL();
    this.addPostmessageListner();
  }

  mountURL() {
    const mainDomain = this.mainDomain();
    const baseURL = ['production', 'staging'].includes(process.env.NODE_ENV)
      ? `https://${this.selectedSchool.subdomain}.${mainDomain}`
      : 'http://localhost:9000';

    const iframeURL = new URL(process.env.VUE_APP_XAVIER_ADMIN_EDOOLS_IFRAME_URL);

    iframeURL.hash = this.page;

    Object.keys(this.params).forEach(paramKey => iframeURL.searchParams.append(paramKey, this.params[paramKey]));

    iframeURL.searchParams.append('target', 'iframe');
    iframeURL.searchParams.append('_base_url', baseURL);
    iframeURL.searchParams.append('sso_credentials', this.loggedUser.credentials);
    iframeURL.searchParams.append('sso_school_id', this.selectedSchool.id);
    iframeURL.searchParams.append('sso_email', this.loggedUser.email);

    this.src = iframeURL.href;
  }

  mainDomain() {
    return this.selectedSchool.account_type === 'herospark'
      ? process.env.VUE_APP_HERO_DOMAIN
      : process.env.VUE_APP_DOMAIN_URL;
  }

  addPostmessageListner() {
    window.addEventListener(
      'message',
      event => {
        if (!['localhost', 'herospark.com'].find(domain => event.origin.includes(domain))) return;

        const data = this.parseMessage(event.data);
        if (!data) return;

        this.processMessage(data);
      },
      false
    );
  }

  parseMessage(message: string) {
    try {
      return JSON.parse(message);
    } catch (error) {
      return null;
    }
  }

  processMessage({ type, data }: { type: 'loader-finish' | 'resize'; data: any }) {
    const mapTypeToProcessor = {
      'loader-finish': this.processLoaderFinish,
      resize: this.setIframeHeight,
    };

    mapTypeToProcessor[type].call(this, data);
  }

  processLoaderFinish(_: any) {
    this.isLoading = false;
  }

  setIframeHeight({ height }: { height: number }) {
    const iframe = this.edoolsIframe;
    iframe.$el.style.height = `${height}px`;
  }
}
